/* You can add global styles to this file, and also import other style files */
@import "~@ng-select/ng-select/themes/default.theme.css";

.badge-brown {
    color: #fff;
    background-color: #964B00;
}

.badge-yellow {
    color: #fff;
    background-color: #FFD500;
}